import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/user'
import commonReducer from '../slices/common'
import gstrReducer from '../slices/forms/gstr1'
import refundReducer from '../slices/forms/refund'
import staffReducer from '../slices/staff'
import gstr3bReducer from '../slices/forms/gstr3b'
import gstr9Reducer from '../slices/forms/gstr9'
import gstr9CReducer from '../slices/forms/gstr9c'
import notificationReducer from '../slices/notification'
import facilitationReducer from '../slices/forms/facilitation'
import litigationReducer from '../slices/forms/litigation'
import hsnSacReducer from "../slices/forms/hsnSacReducer"
 
export default configureStore({
    reducer: {
        user: userReducer,
        common: commonReducer,
        gstr: gstrReducer,
        refund: refundReducer,
        staff: staffReducer,
        gstr3b: gstr3bReducer,
        gstr9: gstr9Reducer,
        gstr9C: gstr9CReducer,
        notification: notificationReducer,
        facilitation: facilitationReducer,
        litigation: litigationReducer,
        hsnSac: hsnSacReducer,


    },
})