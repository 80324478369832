import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from '../../services/instance'
import { CREATE_QUERY,LIST_HSNSAC,QUERY_LIST,CLIENT_QUERY_DETAILS,CREATE_CLIENT_COMMENT,COMMENT_LIST,QUERY_APPROVE_COMPLETE } from '../../constants/services'

export const getHsnSac = createAsyncThunk(
    "hsnSac/getHsnSac",
    async ({ page = 1, perpage = 25,query,types } , thunkAPI) => {
      try {
        const response = await axiosInstance.post(LIST_HSNSAC, {
          page,
          perpage,
          query,
          types,
        });
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const QueryList = createAsyncThunk(
    "hsnSac/QueryList",
    async ({page = 1, perpage = 25, status=[]}, thunkAPI) => {
      try {
        const response = await axiosInstance.post(QUERY_LIST, {
          page,
          perpage,
          status,
        });
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const createQueries = createAsyncThunk(
    "hsnSac/createQueries",
    async ({ data }, thunkAPI) => {
      try {
        const response = await axiosInstance.post(CREATE_QUERY, data);
          return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );

  export const getQueryDetails = createAsyncThunk(
    'hsnSac/getQueryDetails',
    async ({ id }, thunkAPI) => {
      try {
        const response = await axiosInstance.get(`${CLIENT_QUERY_DETAILS}${id}`);
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const createClienComment = createAsyncThunk(
    'hsnSac/createClienComment',
    async ({ id,comment }, thunkAPI) => {
      try {
        const response = await axiosInstance.post(`${CREATE_CLIENT_COMMENT}${id}`,{
          comment
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const getCommentList = createAsyncThunk(
    'hsnSac/getCommentList',
    async ({page = 1, perpage = 25,id }, thunkAPI) => {
      try {
        const response = await axiosInstance.post(`${COMMENT_LIST}${id}`,{
          page,
          perpage
        });
        return response.data; 
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data); 
      }
    }
  );

  export const queryComplete = createAsyncThunk(
    "hsnSac/queryComplete",
    async ({ approve, id }, thunkAPI) => {
      try {
        const response = await axiosInstance.post(`${QUERY_APPROVE_COMPLETE}${id}`,  { approve });
          return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err?.response?.data);
      }
    }
  );
  

export const hsnSacSlice = createSlice({
  name: "hsnSac",
  initialState: {
    hsnSacData: [],
    queryDetails: [],
    commentList:[],
    loading: false,
    error: null,
    success: false,
    successMsg: null,
    total_count: 0,
  },
  reducers: {
    resetHsnSacState(state) {
      state.error = null;
      state.success = false;
      state.successMsg = null;
      // state.total_count = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHsnSac.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getHsnSac.fulfilled, (state, action) => {
        state.hsnSacData = action.payload.data?.list || [];
        state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getHsnSac.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(QueryList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(QueryList.fulfilled, (state, action) => {
        state.hsnSacData = action.payload.data?.list || [];
        state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(QueryList.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(createQueries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createQueries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Created successfully";
       
      })
      .addCase(createQueries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(getQueryDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getQueryDetails.fulfilled, (state, action) => {
        state.queryDetails = action.payload.data || [];
        state.loading = false;
        // state.success = true;
        // state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getQueryDetails.rejected, (state, action) => {
        state.loading = false;
        // state.error = errorMsgCreator(action.payload);
      })

      .addCase(createClienComment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClienComment.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Created successfully";
       
      })
      .addCase(createClienComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Error";
      })

      .addCase(getCommentList.pending, (state) => {
        // state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCommentList.fulfilled, (state, action) => {
        state.commentList = action.payload.data?.list || [];
        // state.loading = false;
        // state.success = true;
        state.total_count = action.payload.data?.total_records || 0;
      })
      .addCase(getCommentList.rejected, (state, action) => {
        // state.loading = false;
        state.error = errorMsgCreator(action.payload);
      })

      .addCase(queryComplete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(queryComplete.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = "Query mark as completed";
       
      })
      .addCase(queryComplete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
  },
});

export const { resetHsnSacState } = hsnSacSlice.actions;
export default hsnSacSlice.reducer;