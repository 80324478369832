import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Table, Badge, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import Base from '../../common/Base';
import { FaExpand, FaTrashCan, FaUserTie, FaUser } from 'react-icons/fa6';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import './hsnSac.css';
import PaginationComponent from '../../../constants/PaginationComponent';
import {
  getCommentList,
  resetHsnSacState,
  createClienComment,
  getQueryDetails,
  queryComplete
} from '../../../slices/forms/hsnSacReducer';
import { modules } from '../../../constants/common';
const QueryDetailsPage = () => {
  const { queryDetails, commentList, total_count, loading, error, success, successMsg } =
    useSelector((state) => state.hsnSac);
  const [current_page, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [quillContent, setQuillContent] = useState('');
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const query = location.state?.query;
  const [expandedComments, setExpandedComments] = useState({});
  const per_page = 25;
  const { query_id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getQueryDetails({ id: query_id })).unwrap();
        await dispatch(
          getCommentList({
            id: query_id
          })
        ).unwrap();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch, query_id]);

  const handlePagination = async (page) => {
    try {
      setCurrentPage(page);
      await dispatch(
        getCommentList({
          page: page,
          perpage: per_page,
          clientId: query.client_details?.id,
          id: query_id
        })
      ).unwrap();
    } catch (error) {
      console.error('Error fetching comments for the selected page:', error);
    }
  };

  const hsnList = Array.isArray(queryDetails?.hsn_list) ? queryDetails.hsn_list : [];

  const statusMap = {
    1: { text: 'Completed', icon: <FaCheckCircle />, variant: 'success' },
    2: {
      text: 'Waiting for admin reply',
      icon: <FaExclamationCircle />,
      variant: 'warning'
    },
    3: {
      text: 'Waiting for client reply',
      icon: <FaExclamationCircle />,
      variant: 'warning'
    },
    0: { text: 'No Action', icon: <FaExclamationCircle />, variant: 'warning' }
  };

  const badge = statusMap[queryDetails?.status];

  const isContentEmpty = (content) => {
    const strippedContent = content.replace(/<[^>]+>/g, '').trim(); // Remove HTML tags and trim
    return strippedContent === '';
  };

  const handleQuillChange = (value) => {
    setQuillContent(value);
  };

  const handleShowModal = () => {
    setShowCompleteModal(true);
  };

  const handleCloseModal = () => {
    setShowCompleteModal(false);
  };

  const handleComplete = async () => {
    try {
      await dispatch(queryComplete({ approve: 1, id: query_id })).unwrap();
      await dispatch(getQueryDetails({ id: query_id })).unwrap();
      await dispatch(
        getCommentList({
          id: query_id
        })
      ).unwrap();
    } catch (error) {
      console.error('Failed to mark the query as complete:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        createClienComment({
          id: query_id,
          comment: quillContent
        })
      ).unwrap();
      await dispatch(getQueryDetails({ id: query_id })).unwrap();
      await dispatch(
        getCommentList({
          id: query_id
        })
      ).unwrap();
      setQuillContent('');
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  const toggleCommentView = (index) => {
    setExpandedComments((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <Base hideDatepicker={true}>
      {loading && (
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!loading && (
        <div className="p-4">
          <span className={'bg-[#0d4473] text-white p-2 px-3 rounded-full relative'}>
            <span className="font-poppins fw-bold">Query Details</span>
            <div className=" absolute w-0 h-0 left-10 -bottom-2 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[10px] border-t-[#0d4473]"></div>
          </span>

          {/* Alert Messages */}
          {(success || error) && (
            <div className="row mt-4">
              <div className="col-md-12 justify-content-center d-flex">
                <Alert
                  dismissible
                  variant={success ? 'success' : 'danger'}
                  onClose={() => dispatch(resetHsnSacState())}>
                  <p className="mb-0">{success ? successMsg : error}</p>
                </Alert>
              </div>
            </div>
          )}
          <div className="mt-4 p-4 rounded shadow-sm">
            <Row className="align-items-center">
              <Col xs={10}>
                <h4 className="mb-2">Description:</h4>
                <div dangerouslySetInnerHTML={{ __html: queryDetails?.query }} />
                {queryDetails?.status !== 1 && (
                  <div className="py-2">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={handleShowModal}
                      style={{ whiteSpace: 'nowrap' }}>
                      Mark as Complete
                    </Button>
                    <Modal show={showCompleteModal} onHide={handleCloseModal} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>Are you sure you want to mark this query as completed?</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleComplete();
                            handleCloseModal();
                          }}>
                          Complete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                )}
              </Col>

              {/* Badge Column */}
              <Col xs={2} className="text-end " style={{ paddingLeft: '0px' }}>
                {badge && (
                  <Button
                  variant={badge.variant}
                    style={{
                      whiteSpace: 'nowrap',
                      cursor: 'default',
                      pointerEvents: 'none'
                    }}
                    className="px-3 py-2 d-flex align-items-center gap-2 badge-custom">
                    {badge.icon} {badge.text}
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5>HSN/SAC Details</h5>
            <Table striped>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>HSN/SAC</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {hsnList.length > 0 ? (
                  hsnList.flat().map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.hsn_number}</td>
                      <td>{item.description}</td>
                      <td>{item.rate}</td>
                      <td>{item.updated_time_formatted?.split('T')[0]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No HSN/SAC details available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {queryDetails?.status === 3 && (
            <div className="mt-4">
              <h5>Write a Comment on this:</h5>
              <ReactQuill
                theme="snow"
                placeholder="Type your comments here..."
                modules={modules}
                value={quillContent}
                onChange={handleQuillChange}
              />

              {/* Action Buttons */}
              <div className="mt-4 d-flex justify-content-end gap-2">
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={isContentEmpty(quillContent)}>
                  Submit
                </Button>
              </div>
            </div>
          )}

          {/* Comments Section */}
          <div className="mt-4">
            <h5>Comments</h5>
            <div className="bg-light p-3 rounded shadow-sm">
              {commentList.length > 0 ? (
                commentList.map((comment, index) => (
                  <Row key={index} className="align-items-center border-bottom py-2">
                    {/* Profile Icon */}
                    <Col xs={1} className="d-flex justify-content-center">
                      {comment.team === 1 ? (
                        <FaUserTie size={20} className="text-primary" />
                      ) : (
                        <FaUser size={20} className="text-success" />
                      )}
                    </Col>

                    {/* Comment Details */}
                    <Col xs={8}>
                      <strong>{comment.team === 1 ? 'Admin' : 'Client'}</strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            expandedComments[index] || comment.comment.split(' ').length <= 15
                              ? comment.comment
                              : `${comment.comment.split(' ').slice(0, 15).join(' ')}...`
                        }}
                      />

                      <small className="text-muted">
                        {comment.created_user_profile ? (
                          <>
                            <strong>{comment.created_user_profile.name}</strong> |{' '}
                            {comment.created_user_profile.email} |{' '}
                          </>
                        ) : (
                          <>
                            <strong>Unknown User</strong> | <span>NA</span> |{' '}
                          </>
                        )}
                        {comment.created_time_formatted
                          ? new Date(comment.created_time_formatted).toLocaleDateString()
                          : 'NA'}{' '}
                        |{' '}
                        {comment.created_time_formatted
                          ? new Date(comment.created_time_formatted).toLocaleTimeString(undefined, {
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true
                            })
                          : 'NA'}
                      </small>
                    </Col>

                    {/* Actions */}
                    <Col xs={2} className=" d-flex text-end justify-content-end">
                      <a
                        className="p-0 mx-2 text-sm d-flex align-items-center cursor-pointer"
                        onClick={() => toggleCommentView(index)}>
                        {expandedComments[index] ? 'Show Less' : 'Show More'}{' '}
                        <FaExpand className="ml-1" />
                      </a>
                    </Col>
                  </Row>
                ))
              ) : (
                <div className="text-center py-3">
                  <strong>No comments available</strong>
                </div>
              )}
              <PaginationComponent
                total_count={total_count}
                current_page={current_page}
                handleOnClick={handlePagination}
                per_page={per_page}
              />
            </div>
          </div>
        </div>
      )}
    </Base>
  );
};

export default QueryDetailsPage;
