import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'remixicon/fonts/remixicon.css';
import 'handsontable/dist/handsontable.full.min.css';

import './assets/css/backend-plugin.min.css';
import './assets/css/backend.css';
import './App.css';
import './assets/css/base.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnProtectedRoute';
import SignIn from './components/SignIn';
import Gstr1 from './components/forms/Gstr1';
import GstrForms from './components/forms/GstrForms';
import Dashboard from './components/Dashboard';
import EditUserForm from './components/ManageProfile';
import axiosInstance from './services/instance';
import { PROFILE } from './constants/services';
import { useDispatch } from 'react-redux';
import { setUserData, setAuthenticatedStatus } from './slices/user';
import Refunds from './components/forms/refund/Refunds';
import LitigationList from './components/forms/litigations/litigationList';
import GstRepoComponent from './components/forms/gstRepo/gstRespo';
import ExportImportFacilitation from './components/forms/ExportImportFacilitation/ExportImportFacilitation';
import HsnSacComponent from './components/forms/hsnSac/hsnSac';
import QueryDetailsPage from './components/forms/hsnSac/QueryDetailsPage';
import { Toaster } from 'react-hot-toast';
import StaffModule from './components/staff/StaffModule';
import AddStaff from './components/staff/AddStaff';
import StaffList from './components/staff/StaffList';
import EditStaff from './components/staff/EditStaff';
import UnAuthorized from './components/common/UnAuthorized';
import Gstr3B from './components/forms/gstr3b/Gstr3b';
import Gstr9 from './components/forms/gstr9/Gstr9';
import Gstr9C from './components/forms/gstr9c/Gstr9c';
import { generateToken, messaging } from "./notification/firebaseConfig";
import { setFcmDeviceToken, setFcmNotification } from "./slices/notification";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadProfile() {
      try {
        if (localStorage.getItem('accessToken')) {
          const result = await axiosInstance.get(PROFILE);
          const { data: userData } = result;
          const { data: user, refresh, token } = userData;
          const { email, id, name, phone, permission_code, role } = user;
          dispatch(
            setUserData({ email, id, name, mobile: phone, permission_code, role: role?.name })
          );
          dispatch(setAuthenticatedStatus(true));
        } else {
          throw new Error('No access token found');
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        dispatch(setAuthenticatedStatus(false));
        return <Navigate to="/login" />;
      } finally {
        setLoading(false);
      }
    }
    loadProfile();
  }, []);

  const [foregroundMessageReceived, setForegroundMessageReceived] = useState(false);

  useEffect(() => {
    const setToken = async () => {
      const deviceToken = await generateToken();
      console.log(deviceToken);
      if(deviceToken)
        dispatch(setFcmDeviceToken(deviceToken));
    }
    setToken();
  }, [dispatch]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (!foregroundMessageReceived && event.data?.data?.fcm_app === 'client') {
            console.log('Received message from service worker:', event.data?.data);
            dispatch(setFcmNotification(event.data?.data));
            
          }
        });
    }
  }, [foregroundMessageReceived, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Toaster />
      <Routes>
        <Route
          path="/login"
          element={
            <UnprotectedRoute>
              <SignIn />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/gst-forms/gstr1"
          element={
            <ProtectedRoute>
              <Gstr1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/refunds"
          element={
            <ProtectedRoute>
              <Refunds />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/litigations"
          element={
            <ProtectedRoute>
              <LitigationList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/Export-Import-Facilitation"
          element={
            <ProtectedRoute>
              <ExportImportFacilitation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/gstRepo"
          element={
            <ProtectedRoute>
              <GstRepoComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/hsn/sac"
          element={
            <ProtectedRoute>
              <HsnSacComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/query-details/:query_id"
          element={
            <ProtectedRoute>
              <QueryDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/gstr3b"
          element={
            <ProtectedRoute>
              <Gstr3B />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/gstr9"
          element={
            <ProtectedRoute>
              <Gstr9 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms/gstr9c"
          element={
            <ProtectedRoute>
              <Gstr9C />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gst-forms"
          element={
            <ProtectedRoute>
              <GstrForms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-profile"
          element={
            <ProtectedRoute>
              <EditUserForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-staff"
          element={
            <ProtectedRoute>
              <StaffModule />
            </ProtectedRoute>
          }>
          <Route element={<StaffList />} index />
          <Route path="add-staff" element={<AddStaff />} />
          <Route path="edit-staff/:staff_id" element={<EditStaff />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
        <Route element={<UnAuthorized />} path="/authorization-error" />
      </Routes>
    </Router>
  );
}

export default App;
