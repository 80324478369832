export const SIGN_IN = '/api/v1/user/login';
export const PROFILE = '/api/v1/user/profile';
export const GET_FORM_DATA = '/api/v1/gstr/view/sheet';
export const GET_CELL_DATA = `/api/v1/gstr/fetch/comments`;
export const ADD_COMMENT = '/api/v1/gstr/add/comment';
export const UPLOAD_FILES = '/api/v1/gstr/uploads';
export const GSTR3B_GET_FORM_DATA = '/api/v1/gstr3b/view/sheet';
export const GSTR3B_GET_CELL_DATA = `/api/v1/gstr3b/fetch/comments`;
export const GSTR3B_ADD_COMMENT = '/api/v1/gstr3b/add/comment';
export const GSTR3B_UPLOAD_FILES = '/api/v1/gstr3b/uploads';
export const GSTR9_GET_FORM_DATA = '/api/v1/gstr9/view/sheet';
export const GSTR9_GET_CELL_DATA = `/api/v1/gstr9/fetch/comments`;
export const GSTR9_ADD_COMMENT = '/api/v1/gstr9/add/comment';
export const GSTR9_UPLOAD_FILES = '/api/v1/gstr9/uploads';
export const GSTR9C_GET_FORM_DATA = '/api/v1/gstr9c/view/sheet';
export const GSTR9C_GET_CELL_DATA = `/api/v1/gstr9c/fetch/comments`;
export const GSTR9C_ADD_COMMENT = '/api/v1/gstr9c/add/comment';
export const GSTR9C_UPLOAD_FILES = '/api/v1/gstr9c/uploads';
export const GET_REFUND_FORM_DATA = '/api/v1/refund/view/sheet';
export const GET_REFUND_CELL_DATA = `/api/v1/refund/fetch/comments`;
export const ADD_REFUND_COMMENT = '/api/v1/refund/add/comment';
export const UPLOAD_REFUND_FILES = '/api/v1/refund/upload/files';
export const GET_REFUND_DATA = '/api/v1/client/refund/list/arn';
export const UPDATE_PROFILE = '/api/v1/user/manage/profile';
export const UPDATE_PASSWORD = '/api/v1/user/password/update';
export const HANDLE_REFUND_SHEET_APPROVAL = '/api/v1/refund/approve';
export const HANDLE_MANAGER_REFUND_SHEET_APPROVAL = '/api/v1/refund/manager/approve';
export const HANDLE_GSTR_SHEET_APPROVAL = '/api/v1/gstr/approve';
export const HANDLE_MANAGER_GSTR_SHEET_APPROVAL = '/api/v1/gstr/manager/approve';
export const HANDLE_GSTR3B_SHEET_APPROVAL = '/api/v1/gstr3b/approve';
export const HANDLE_MANAGER_GSTR3B_SHEET_APPROVAL = '/api/v1/gstr3b/manager/approve';
export const HANDLE_GSTR9_SHEET_APPROVAL = '/api/v1/gstr9/approve';
export const HANDLE_MANAGER_GSTR9_SHEET_APPROVAL = '/api/v1/gstr9/manager/approve';
export const HANDLE_GSTR9C_SHEET_APPROVAL = '/api/v1/gstr9c/approve';
export const HANDLE_MANAGER_GSTR9C_SHEET_APPROVAL = '/api/v1/gstr9c/manager/approve';
export const CREATE_REFUND_PERIOD = '/api/v1/refund/create/folder';
export const LIST_ROLES = '/api/v1/user/role/list';
export const CREATE_STAFF = '/api/v1/user/create/staff';
export const GET_STAFF_LIST = '/api/v1/user/staff/list';
export const STAFF_STATUS_UPDATE = '/api/v1/user/change/status/staff';
export const GET_STAFF_INFO = '/api/v1/user/view/staff';
export const UPDATE_STAFF= 'api/v1/user/update/staff';
export const LIST_NOTIFICATIONS= 'api/v1/user/staff/notifications';

export const GET_FACILITATION_NAME= "api/v1/facilitation/lists";
export const GET_LITIGATION_LIST= "api/v1/litigation/lists";

export const LIST_HSNSAC= "api/v1/hsnrepo/search";
export const QUERY_LIST= "api/v1/hsnrepo/query/list/0";
export const CREATE_QUERY= "api/v1/hsnrepo/query/new";
export const CLIENT_QUERY_DETAILS= "api/v1/hsnrepo/query/client/view/";
export const CREATE_CLIENT_COMMENT= "api/v1/hsnrepo/query/client/reply/";
export const COMMENT_LIST= "api/v1/hsnrepo/query/comments/client/";
export const QUERY_APPROVE_COMPLETE= "api/v1/hsnrepo/query/client/approve/"



