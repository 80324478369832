import React, { useState } from "react";
// import { DatePicker } from "antd";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { FaDownload, FaSyncAlt, FaCheckCircle, FaClock, FaSpinner } from "react-icons/fa";
import "./gstRepo.css";
import Base from '../../common/Base';
import ChatPopup from '../../common/ChatPopup';


const GstRepoComponent = () => {
   const [spinningIndex, setSpinningIndex] = useState(null);
   const handleRefreshClick = (index) => {
    setSpinningIndex(index); 
    setTimeout(() => setSpinningIndex(null), 3000);
  };
   // Dummy data for grids
  const dummyData = [
    {
      title: "Card 1",
      dateRange: '01-01-24 to 31-01-24',
      status: 'Completed',
      updatedDate: '01-02-24'
    },
    {
      title: "Card 2",
      dateRange: '01-02-24 to 29-02-24',
      status: 'Pending',
      updatedDate: '02-03-24'
    },
    {
      title: "Card 3",
      dateRange: '01-03-24 to 31-03-24',
      status: 'In Progress',
      updatedDate: '01-04-24'
    },
    {
      title: "Card 4",
      dateRange: '01-03-24 to 31-03-24',
      status: 'Completed',
      updatedDate: '01-04-24'
    }
  ];

  return (
    <Base header="gstRepo" headerTitle={'GST Repository'}>
      <Container className="mt-4">
        {/* Filter Section */}
        <div className="mb-4">
          <Form.Label className="font-weight-bold mb-2 ">Filter:</Form.Label>
          <div className="d-flex gap-2">
            <Button variant="outline-primary">1 Month</Button>
            <Button variant="outline-primary">2 Month</Button>
            <Button variant="outline-primary">3 Month</Button>
          </div>
        </div>

        {/* Grid Section */}
        <Row>
          {dummyData.map((item, index) => (
            <Col md={3} sm={6} className="mb-4" key={index}>
              <Card
                className="h-80 shadow-sm position-relative"
                style={{ borderRadius: '10px', overflow: 'hidden', border: '1px solid #0000002d' }}>
                <Card.Body>
                  {/* Refresh Icon */}
                  <FaSyncAlt
                    className={`position-absolute ${spinningIndex === index ? 'spin' : ''}`}
                    style={{
                      top: '10px',
                      right: '10px',
                      cursor: 'pointer',
                      color: '#0d4473',
                      transition: 'transform 0.1s ease-in-outsetSpinningIndex'
                    }}
                    onClick={() => handleRefreshClick(index)}
                  />
                  {/* Title */}
                  <div
                    className="d-flex align-items-center justify-content-between mb-2 p-2"
                    style={{
                      backgroundColor: "#f5f9fc",
                      color: "#0d4473",
                      fontWeight: "bold",
                    }}
                  >
                    <span>Title:</span>
                    <span
                      style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "5px",
                        color: "grey",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {item.title}
                    </span>
                  </div>
                  {/* Date Range */}
                  <div
                    className="d-flex align-items-center justify-content-between mb-2 p-2"
                    style={{
                      backgroundColor: "#f5f9fc",
                      color: "#0d4473",
                      fontWeight: "bold",
                    }}
                  >
                    <span>Date Range:</span>
                    <span
                      style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "5px",
                        color: "grey",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {item.dateRange}
                    </span>
                  </div>

                  {/* Status */}
                  <div
                    className="d-flex align-items-center justify-content-between mb-2 p-2"
                    style={{
                      backgroundColor: "#f5f9fc",
                      color: "#0d4473",
                      fontWeight: "bold",
                    }}
                  >
                    <span>Status:</span>
                    <span
                      className="d-flex align-items-center"
                      style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "5px",
                        color: "grey",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {item.status === "Completed" && (
                        <FaCheckCircle className="me-2 text-success" />
                      )}
                      {item.status === "Pending" && (
                        <FaClock className="me-2 text-warning" />
                      )}
                      {item.status === "In Progress" && (
                        <FaSpinner className="me-2 text-primary" />
                      )}
                      {item.status}
                    </span>
                  </div>

                  {/* Updated Date */}
                  <div
                    className="d-flex align-items-center justify-content-between mb-2 p-2"
                    style={{
                      backgroundColor: "#f5f9fc",
                      color: "#0d4473",
                      fontWeight: "bold",
                    }}
                  >
                    <span>Updated Date:</span>
                    <span
                      style={{
                        backgroundColor: "#f9f9f9",
                        borderRadius: "5px",
                        color: "grey",
                        padding: "0.25rem 0.5rem",
                      }}
                    >
                      {item.updatedDate}
                    </span>
                  </div>
                </Card.Body>

                {/* Footer Section */}
                <Card.Footer
                  className="text-end d-flex align-items-center justify-content-between"
                  style={{ backgroundColor: '#f5f9fc' }}>
                  <span className="text-muted">Download</span>
                  <FaDownload style={{ cursor: 'pointer', color: '#0d4473' }} />
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <ChatPopup chat_type="GSTREPO" />
    </Base>
  );
};

export default GstRepoComponent;
