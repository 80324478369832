import React, { useState, useEffect } from 'react';
import Base from '../../common/Base';
import { Row, Col, Button, Form, Tab, Tabs, Table } from 'react-bootstrap';
import './hsnSac.css';
import CreateQueryModal from './CreateQueries';
import { FaCopy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import DescriptionCell from './descriptionCell';
import PaginationComponent from '../../../constants/PaginationComponent';
import { getHsnSac, resetHsnSacState, QueryList } from '../../../slices/forms/hsnSacReducer';
const HsnSacComponent = () => {
  const [activeTab, setActiveTab] = useState('HSN');
  const [selectedRows, setSelectedRows] = useState([]);
  const [showCreateQueryModal, setShowCreateQueryModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [current_page, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const per_page = 25;

  const { hsnSacData, total_count, loading, error, success, successMsg } = useSelector(
    (state) => state.hsnSac
  );

  useEffect(() => {
    const fetchData = () => {
      const params = { page: current_page, perpage: per_page };
      if (query.trim() && activeTab !== 'QUERIES') {
        params.query = query.trim();
      }

      if (selectedStatus) {
        params.status = [selectedStatus === 'Completed' ? 1 : 0];
      }
      switch (activeTab) {
        case 'HSN':
        case 'SAC':
          dispatch(getHsnSac({ ...params, types: activeTab }));
          break;
        case 'QUERIES':
          dispatch(QueryList(params));
          break;
        default:
          console.warn('Unhandled tab type:', activeTab);
      }
    };

    fetchData();
  }, [dispatch, activeTab, selectedStatus, query]);

  const handlePagination = (page) => {
    setCurrentPage(page);
    const params = { page: page, perpage: per_page };

    if (query.trim() && activeTab !== 'QUERIES') {
      params.query = query.trim();
    }

    if (selectedStatus) {
      params.status = [selectedStatus === 'Completed' ? 1 : 0];
    }
    switch (activeTab) {
      case 'HSN':
      case 'SAC':
        dispatch(getHsnSac({ ...params, types: activeTab }));
        break;
      case 'QUERIES':
        dispatch(QueryList({ ...params, types: 'QUERIES' }));
        break;
      default:
        console.warn('Unhandled tab type for pagination:', activeTab);
    }
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleQueryClick = (query) => {
    navigate(`/query-details/${query.id}`);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const renderTable = (type) => {
    if (hsnSacData.length === 0) {
      return (
        <tr>
          <td colSpan="5" style={{ textAlign: 'center', padding: '20px' }}>
            No data available
          </td>
        </tr>
      );
    }

    return hsnSacData
      .filter((item) => item.type === type)
      .map((item, index) => (
        <tr
          key={item.id}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
          <td>
            <Form.Check
              type="checkbox"
              onChange={() => handleCheckboxChange(item.id)}
              checked={selectedRows.includes(item.id)}
              id={`checkbox-${item.id}`}
              className="custom-checkbox"
            />
          </td>
          <td
            className="position-relative"
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(null)}>
            {item.hsn_number}
            {hoveredRow === index && (
              <Button
                variant="info"
                size="sm"
                className="copy-button position-absolute"
                onClick={() => handleCopy(item.hsn_number, index)}>
                <FaCopy style={{ fontSize: '10px' }} />
              </Button>
            )}
            {/* "Copied" message overlay */}
            {copiedIndex === index && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  fontSize: '9px',
                  fontWeight: 'bold',
                  color: 'green',
                  opacity: '100%',
                  pointerEvents: 'none',
                  animation: 'fadeInOut 3s'
                }}>
                Copied
              </div>
            )}
          </td>

          <DescriptionCell description={item.description || ''} format={false}/>
          <td>{item.rate}</td>
          <td>{item.updated_time_formatted.split('T')[0]}</td>
        </tr>
      ));
  };
  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleCreateQueryModal = (value) => {
    setShowCreateQueryModal(true);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000);
  };

  const getSelectedItems = () => {
    return hsnSacData.filter((item) => selectedRows.includes(item.id));
  };

  const renderTextareaContent = () => {
    const selectedItems = getSelectedItems();
    if (selectedItems.length === 0) {
      return 'No items selected';
    } else {
      return selectedItems.map((item, index) => `(${index + 1}). ${item.hsn_number}`).join('\n');
    }
  };

  const renderButtons = () => {
    let selectedItems;
    try {
      selectedItems = getSelectedItems();
  
      if (!Array.isArray(selectedItems)) {
        console.error("getSelectedItems did not return an array");
        return (
          <Button variant="secondary" disabled>
            Error fetching items
          </Button>
        );
      }
  
      if (selectedItems.length === 0) {
        return (
          <Button variant="secondary" disabled>
            No items selected
          </Button>
        );
      } else {
        return (
          <>
            {selectedItems.slice(0, 2).map((item, index) => (
              <Button
                key={item?.id || index}
                variant="secondary"
                disabled
                className="me-2"
              >
                ({index + 1}) {item?.hsn_number || "Unknown"}
              </Button>
            ))}
            {selectedItems.length > 2 && (
              <Button variant="secondary" disabled>
                Total {selectedItems.length} items selected
              </Button>
            )}
          </>
        );
      }
    } catch (error) {
      console.error("Error in renderButtons:", error);
      return (
        <Button variant="secondary" disabled>
          Error
        </Button>
      );
    }
  };

  return (
    <Base header="HSN/SAC" headerTitle={'HSN/SAC'}>
      <div className="mt-4 d-flex justify-content-end position-relative">
        {success && (
          <div className="alert-overlay-absolute">
            <Alert dismissible variant="success">
              <p>{successMsg}</p>
            </Alert>
          </div>
        )}
      </div>
      <div className="m-4" >
        <Tabs
          justify
          transition={false}
          activeKey={activeTab}
          onSelect={(key) => {
            setActiveTab(key);
            setSelectedRows([]);
            setCurrentPage(1);
            dispatch(resetHsnSacState());
            setSelectedStatus('');
          }}
          style={{
            background: "#eaeded",
            boxShadow: "0px 4px 6px  rgba(0, 0, 0, 0.1)",
            '--bs-nav-tabs-link-active-bg': 'white',
            '--bs-nav-tabs-link-active-color': '#0d4473',
          }}
          className="mb-4">
          <Tab eventKey="HSN" title="HSN">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Form.Control
                  type="text"
                  placeholder="Search HSN..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
                <div className="d-flex align-items-center gap-2">{renderButtons()}</div>
                <Button
                  variant="primary"
                  disabled={selectedRows.length === 0}
                  onClick={() => setShowCreateQueryModal(true)}>
                  Create Queries
                </Button>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>Selection</th>
                  <th>HSN</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>
                      <Spinner animation="border" role="status" variant="primary"></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderTable('HSN')}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="SAC" title="SAC">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Form.Control
                  type="text"
                  placeholder="Search SAC..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
                <div className="d-flex align-items-center gap-2">{renderButtons()}</div>
                <Button
                  variant="primary"
                  disabled={selectedRows.length === 0}
                  onClick={() => handleCreateQueryModal()}>
                  Create Queries
                </Button>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>Selection</th>
                  <th>SAC</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>
                      <Spinner animation="border" role="status" variant="primary"></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderTable('SAC')}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="QUERIES" title="Queries">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                {/* <Form.Control
                  type="text"
                  placeholder="Search Queries"
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                /> */}
                <Form.Select
                  className="w-auto"
                  value={selectedStatus}
                  onChange={handleStatusChange}>
                  <option value="">All Status</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center', padding: '20px' }}>
                      <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                ) : hsnSacData.length === 0 ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: 'center', padding: '20px' }}>
                      No data available
                    </td>
                  </tr>
                ) : (
                  hsnSacData.map((query, index) => (
                    <tr
                      key={index}
                      onClick={() => handleQueryClick(query)}
                      style={{ cursor: 'pointer' }}>
                      <td>{index + 1}</td>
                      <DescriptionCell description={query.query || ''} format={true} />
                      <td>{query.status === 1 ? 'Completed' : 'Pending'}</td>
                      <td>{query.updated_time_formatted.split('T')[0]}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
        <PaginationComponent
          total_count={total_count}
          current_page={current_page}
          handleOnClick={handlePagination}
          per_page={per_page}
        />
      </div>
      <CreateQueryModal
        show={showCreateQueryModal}
        onHide={() => {
          setShowCreateQueryModal(false);
          setSelectedRows([]);
          setQuery('');
        }}
        selectedDetails={getSelectedItems()}
      />
    </Base>
  );
};

export default HsnSacComponent;
