import React, { useState } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { createQueries } from "../../../slices/forms/hsnSacReducer";
import { modules } from '../../../constants/common';
const CreateQueryModal = ({ show, onHide, selectedDetails }) => {
  const [quillContent, setQuillContent] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const req_ids = selectedDetails.map((item) => item.id); 
    const data = { req_ids, query: quillContent };

    dispatch(createQueries({ data })); 
    setQuillContent("")
    onHide(); 
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Create Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5>Selected Items</h5>
          <div className="mb-3">
            {Array.isArray(selectedDetails) && selectedDetails.length > 0 ? (
              selectedDetails.map((item, index) => (
                <Card key={item.id} className="mb-2">
                  <Card.Body>
                    <Card.Text>
                      {index + 1}. {item.hsnSac} - {item.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>No items selected.</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          <ReactQuill
            theme="snow"
            placeholder="Type your query here..."
            modules={modules}
            value={quillContent}
            onChange={setQuillContent}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!quillContent.trim()} 
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateQueryModal;